import tableGetters from "../../templates/table/getters";

export default {
  ...tableGetters,
  company: state => id => state.company[state.entity[id].company],
  country: state => id => state.country[state.entity[id].country],
  filterCategories: state => id =>
    state.entity[id].filterCategories.map(
      filterCategory => state.category[filterCategory.category]
    ),
  bulditFilterCompaniesWithPercentages: state => id =>
    state.entity[id].filters.map(filter => {
      return {
        companyName: state.company[state.filter[filter.filter].company].name,
        percentage: filter.percentage
      };
    }),
  pedigree: state => filterId => {
    const parents = state.entity[filterId].parentFilters.map(parent => {
      return {
        filter: state.filter[parent.filter],
        percentage: parent.percentage
      };
    });

    const children = state.entity[filterId].filters.map(parent => {
      return {
        filter: state.filter[parent.filter],
        percentage: parent.percentage
      };
    });
    return parents.length > 0 ? parents : children;
  }
};
